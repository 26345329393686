// polyfill for custom elements. Optional, see https://caniuse.com/#feat=custom-elementsv1
import "@webcomponents/custom-elements";

import { registerCustomElement, registerPorts } from "elm-mapbox";

// A Mapbox API token. Register at https://mapbox.com to get one of these. It's free.
const token =
    "pk.eyJ1IjoiYWVtZW5nbyIsImEiOiJjbGsxbWs0emcwNGJuM2xycmV6cmUyM3JiIn0.zqdAQ_gII9bUKiq5Lk01Yg";

// This will add elm-mapbox custom element into the page's registry.
// This **must** happen before your application attempts to render a map.
registerCustomElement({
    token
});